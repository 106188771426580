<template>
    <div class="card">
      <h5>Iniciar sesión</h5>
      <div class="p-fluid">
        <div class="p-field">
          <label for="firstname1">Usuario</label>
          <InputText id="firstname1" type="text" v-model="email"/>
        </div>
        <div class="p-field" style="margin-top: 1em">
          <label for="lastname1">Contraseña</label>
          <InputText id="lastname1" type="password" v-model="password"/>
        </div>
        <div class="p-field" style="margin-top: 1em">
          <Button label="Ingresar" @click="doLogin"/>
        </div>
      </div>
    </div>
</template>

<script>
import { auth } from '../logic/firebase';
var email = "";
var password = "";

export default {
  name: "Login",
  setup(){
    return{
      email,
      password
    }
  },

  methods: {
    async doLogin(){
      console.log("Do login")
      auth.signInWithEmailAndPassword(this.email, this.password)
          .then((userCredential) => {
            // Signed in
            // eslint-disable-next-line no-unused-vars
            var user = userCredential.user;
            // ...
          })
          .catch((error) => {
            console.log("Error: ", error.message)
            //var errorCode = error.code;
            //var errorMessage = error.message;
            this.badLogin();
          });
    },
    async badLogin(){
      console.log("Error loggin in with email: " + this.email)
    }
  }
}
</script>

<style scoped>
  .card{
    margin-left: auto;
    margin-right: auto;
    width: 80%
  }
</style>